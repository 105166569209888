import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import SongMedia from "components/Sections/Song/SongMedia";
import ArticleSidebar from "components/Sections/Article/ArticleSidebar";
import ArticleHeader from "components/Sections/Article/ArticleHeader";
// Model
import { IArticle } from "models/Article";
import { IUser } from "models/User";
import { IOptionalMetaProps } from "models/Seo";

type DataProps = {
  article: IArticle;
  writer: IUser;
  translations: {
    count: number;
  };
  articles: {
    count: number;
  };
};

const WipArticlePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { title, date, description, thumbnail } = data.article.frontmatter;
  const { video, esp_srt, rom_srt, covers } = data.article.frontmatter;
  const { timeToRead, body } = data.article;
  const { translations, articles } = data;
  const writer = data.writer;

  const seo: IOptionalMetaProps = {
    title,
    description:
      description ||
      `Artículo de ${writer.name}${
        data && `, publicado el ${data}.`
      }. - ${title}`,
    author: {
      name: writer.name,
      twitter: writer.twitter,
    },
    image: thumbnail?.url,
    hide: true,
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          {!video ? (
            <ArticleHeader
              title={title}
              thumbnail={thumbnail?.childImageSharp.gatsbyImageData}
              date={date}
              timeToRead={timeToRead}
            />
          ) : (
            <SongMedia
              title={title}
              image={thumbnail}
              video={video}
              covers={covers}
              esp_srt={esp_srt}
              rom_srt={rom_srt}
            />
          )}
          <hr />
          <MDXRenderer>{body}</MDXRenderer>
          <hr />
          {/* <ArticleFooter slug={data.article.fields.slug} message={title} /> */}
          <hr />
        </article>
      </Main>
      <ArticleSidebar
        translationsCount={translations.count}
        articlesCount={articles.count}
        writer={writer}
        kohi={false}
        follow={false}
      />
    </Layout>
  );
};

export default WipArticlePage;

export const pageQuery = graphql`
  query WipArticlePageQuery($slug: String!, $writer: String!) {
    article: mdx(
      fields: { slug: { eq: $slug }, collection: { eq: "articles" } }
      frontmatter: { tags: { eq: "WIP" } }
    ) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD [de] MMMM, YYYY", locale: "es")
        description
        video
        esp_srt {
          publicURL
        }
        rom_srt {
          publicURL
        }
        thumbnail {
          url: publicURL
          childImageSharp {
            gatsbyImageData(
              width: 920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    writer: user(slug: { eq: $writer }) {
      slug
      name
      description
      twitter
      facebook
      instagram
      telegram
      youtube
      spotify
      avatar {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    translations: allMdx(
      filter: {
        frontmatter: { translator: { eq: $writer } }
        fields: { collection: { eq: "songs" } }
      }
    ) {
      count: totalCount
    }

    articles: allMdx(
      filter: {
        frontmatter: { writer: { eq: $writer } }
        fields: { collection: { eq: "articles" } }
      }
    ) {
      count: totalCount
    }
  }
`;
